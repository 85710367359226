/*
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMN0OxdddxkO0XWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMx,               .,cdONMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMl    ',lx0WNo:.          'lkNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMXxl;,.'o0WNkoc0O:                 .cOWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMWd.         .ddlc;.                      ,dXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMNMMMMMMMMMMMM
MMMMMMMW.                                ..';:codk0XMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWx'dMMMMMMMMMMMM
MMMMMMMd  'oOKK0KK0dc;''.,:::clcclllc;,..           ..,:coxk0KNWMMMMMMMMMMMMMMMN0xc.  'MMMMMMMMMMMMM
MMMMMMMk'KMW0xo:. .cKMWKkdoxO00kkxxxkO00kkxdl;,''.                ....''''...        ,WMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMXc  'KMMNkxdxONXOxxxkkkKNNKK0OO0KWNKko:'.                         .kMMMMMMMMMMWKWMM
MMMMMMMMMMMMMMMMMMMK.  lMMMXxxkk0NWXkxOkKKNWMMWWNNWWWWWWWWWXOxl;'.              'lKMMMMMMMMMMNK0XMMM
MMMMMMMMMMMMMMMMMMMMN.  ,WMMWkOKK0NMMNNNXNNWWWWMMMWWWNWWNNNNNNWWWWMWNK0OkkkO0KWMMMMMMMMMWWNXOO0KMMMM
MMMMMMMMMMMMMMMMMMMMMX   'WMMMXNNNNNWMMWNNNNNWNNWMMMMWWNNNNNNNNNNNNNNNWWWXNWWWWNNNNNNKkloOxl:,cWMMMM
MMMMMMMMMMMMMMMMMMMMMMO   ,MMMMNNXXXNNMMMWNNXNNNWNNWMMMMWWWNNNNNNNNXXNXxXKk0OKNOxxO0o':,;;;,,dWMMMMM
MMMMMMMMMMMMMMMMMMMMMMMo   :MMMMNXXXNXXNMMMNXXXNNNNNNNWMMMMMMWNXXK0OKKxdO0d:;:c;c:od,'',o;:xNMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMM,   dMMMMXKNNXKO0WMMMXXXXNNXXK00NNMMMMMMMNKxl;llc,,,,',,;;,,''',lOWMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMW'   OMMMMXXXXXK0XNMMMWKOkkx0OOkolok0WMMMMMMMWN0OxdoollllodkOKNMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMW:   KMMMMNXXXXXKKkWMMMNx,,lc;;'',,,,lkXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMM0,  0MMMMN0dclllod0MMMMO:,,,,,,'',,,',;okKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOdNMMMMXo::c,,,,lXMMMM0o,',',,',,'',,,'.;ldk0KNWMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKd;,,,,,,oXMMMMW0dc,,,,',,,,,'''''',cxXMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKxo:;,,'cxKWMMMMWKOxdlcc:ccldxOXMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWNNNWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
 - Copyright 2023 Bird Eye View Private Ltd. - 

* Project: Birdeyeview responsive website
 * Technologies Used:
 *   - Programming Languages: JavaScript (ES6+), JSON, HTML, CSS
 *   - Frontend Framework: React.js
 *   - Backend Framework: Node.js
 *   - CSS Preprocessor: SCSS
 *   - UI Framework: Material-UI
 *   - Routing: React Router
 *   - State Management: -
 *   - Form Handling: Formik 
 *   - HTTP Requests: -
 *   - Data Visualization: -
 *   - Authentication: -
 *   - Animation: react-slick
 *   - Code Bundler: -
 *   - Package Manager: npm
 *   - Version Control: Git
 *   - Deployment: current->Shapehost, next->nginx
 *
 * Customizations:
 *   - 
 *
 * Author: Divyprakash Dhurandhar
 * Contact: divyaprakash.0426@gmail.com
 * GitHub: github.com/divyaprakash0426

Thanks to: 
- alexandre-debieve on Unsplash
- kvistholt-photography on Unsplash
- tim-mossholder on Unsplash
- chris-reid on Unsplash

=============================================
Built on top of Material Kit by Creative Tim:
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
=============================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";
import Team from "pages/Company/AboutUs/sections/Team";
//import Featuring from "pages/Company/AboutUs/sections/Featuring";
//import Newsletter from "pages/Company/AboutUs/sections/Newsletter";

// Routes
import routes from "routes";
//import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

function AboutUs() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings: ["team", "design", "tool"],
      typeSpeed: 90,
      backSpeed: 90,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  }, []);

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Work with an amazing <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Our team is dedicated to pushing the boundaries of what&apos;s possible. Together, we
              innovate, collaborate, and transform ideas into reality.
            </MKTypography>
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Connect with us
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography
                component="a"
                variant="body1"
                color="white"
                href="https://www.linkedin.com/company/birdeyeview"
                mr={3}
              >
                <i className="fab fa-linkedin" style={{ verticalAlign: "middle" }} />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default AboutUs;
