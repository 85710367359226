/*
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMN0OxdddxkO0XWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMx,               .,cdONMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMl    ',lx0WNo:.          'lkNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMXxl;,.'o0WNkoc0O:                 .cOWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMWd.         .ddlc;.                      ,dXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMNMMMMMMMMMMMM
MMMMMMMW.                                ..';:codk0XMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWx'dMMMMMMMMMMMM
MMMMMMMd  'oOKK0KK0dc;''.,:::clcclllc;,..           ..,:coxk0KNWMMMMMMMMMMMMMMMN0xc.  'MMMMMMMMMMMMM
MMMMMMMk'KMW0xo:. .cKMWKkdoxO00kkxxxkO00kkxdl;,''.                ....''''...        ,WMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMXc  'KMMNkxdxONXOxxxkkkKNNKK0OO0KWNKko:'.                         .kMMMMMMMMMMWKWMM
MMMMMMMMMMMMMMMMMMMK.  lMMMXxxkk0NWXkxOkKKNWMMWWNNWWWWWWWWWXOxl;'.              'lKMMMMMMMMMMNK0XMMM
MMMMMMMMMMMMMMMMMMMMN.  ,WMMWkOKK0NMMNNNXNNWWWWMMMWWWNWWNNNNNNWWWWMWNK0OkkkO0KWMMMMMMMMMWWNXOO0KMMMM
MMMMMMMMMMMMMMMMMMMMMX   'WMMMXNNNNNWMMWNNNNNWNNWMMMMWWNNNNNNNNNNNNNNNWWWXNWWWWNNNNNNKkloOxl:,cWMMMM
MMMMMMMMMMMMMMMMMMMMMMO   ,MMMMNNXXXNNMMMWNNXNNNWNNWMMMMWWWNNNNNNNNXXNXxXKk0OKNOxxO0o':,;;;,,dWMMMMM
MMMMMMMMMMMMMMMMMMMMMMMo   :MMMMNXXXNXXNMMMNXXXNNNNNNNWMMMMMMWNXXK0OKKxdO0d:;:c;c:od,'',o;:xNMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMM,   dMMMMXKNNXKO0WMMMXXXXNNXXK00NNMMMMMMMNKxl;llc,,,,',,;;,,''',lOWMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMW'   OMMMMXXXXXK0XNMMMWKOkkx0OOkolok0WMMMMMMMWN0OxdoollllodkOKNMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMW:   KMMMMNXXXXXKKkWMMMNx,,lc;;'',,,,lkXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMM0,  0MMMMN0dclllod0MMMMO:,,,,,,'',,,',;okKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOdNMMMMXo::c,,,,lXMMMM0o,',',,',,'',,,'.;ldk0KNWMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKd;,,,,,,oXMMMMW0dc,,,,',,,,,'''''',cxXMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKxo:;,,'cxKWMMMMWKOxdlcc:ccldxOXMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWNNNWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
 - Copyright 2023 Bird Eye View Private Ltd. - 

* Project: Birdeyeview responsive website
 * Technologies Used:
 *   - Programming Languages: JavaScript (ES6+), JSON, HTML, CSS
 *   - Frontend Framework: React.js
 *   - Backend Framework: Node.js
 *   - CSS Preprocessor: SCSS
 *   - UI Framework: Material-UI
 *   - Routing: React Router
 *   - State Management: -
 *   - Form Handling: Formik 
 *   - HTTP Requests: -
 *   - Data Visualization: -
 *   - Authentication: -
 *   - Animation: react-slick
 *   - Code Bundler: -
 *   - Package Manager: npm
 *   - Version Control: Git
 *   - Deployment: current->Shapehost, next->nginx
 *
 * Customizations:
 *   - 
 *
 * Author: Divyprakash Dhurandhar
 * Contact: divyaprakash.0426@gmail.com
 * GitHub: github.com/divyaprakash0426

Thanks to: 
- alexandre-debieve on Unsplash
- kvistholt-photography on Unsplash
- tim-mossholder on Unsplash
- chris-reid on Unsplash

=============================================
Built on top of Material Kit by Creative Tim:
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
=============================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components

// Pages
import HomePage from "layouts/pages/landing-pages/home";
import AboutUs from "layouts/pages/company/about-us";
import ContactUs from "layouts/pages/support/contact-us";

const routes = [
  {
    name: "Home",
    route: "/pages/LandingPages/Home",
    component: <HomePage />,
  },
  {
    name: "About Us",
    route: "/pages/company/about-us",
    component: <AboutUs />,
  },
  {
    name: "Contact Us",
    route: "/pages/support/contact-us",
    component: <ContactUs />,
  },
];

export default routes;
