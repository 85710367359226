/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import bgImage from "assets/images/examples/city.jpg";
import team2 from "assets/images/team/sarveshsindhu.jpeg";
import team3 from "assets/images/team/rudrasensindhu.jpeg";
import team4 from "assets/images/team/sarojsindhu.jpeg";

function Team() {
  return (
    <MKBox
      component="section"
      //variant="gradient"
      //bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
      sx={{
        backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) =>
          `${linearGradient(rgba(gradients.dark.main, 0.8), rgba(gradients.dark.state, 0.8))},
            url(${bgImage})`,
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Promoters
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Led by Sarvesh Sindhu, and deeply supported by the pillars of wisdom, Saroj and Rudra
              Sen Sindhu, our promoters bring a unique synergy of vision, resilience, and legacy.
              Together, they inspire us to achieve greatness and remind us that with unity and
              purpose, anything is possible.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team4}
                name="Saroj Sindhu"
                position={{ color: "info", label: "Investor" }}
                description="Director of 12 active companies, Saroj has an extensive portfolio spanning construction, energy, and other business activities. With unwavering commitment, she's played key roles from Indus Portfolio Private Limited to Akshay Urja Manthan Biofuel Private Limited."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                name="Sarvesh Sindhu"
                position={{ color: "info", label: "Founder" }}
                description="Executive Assistant to Aryan Group's Chairman and a tech-enthusiast, Sarvesh is a former basketball captain with keen interests in golf and innovative solutions for the Biogas industry."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={4}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={team3}
                name="Rudra Sen Sindhu"
                position={{ color: "info", label: "Investor" }}
                description="Chairman of Aryan Group, Rudra Sen Sindhu boasts over 40 years in coal mining and logistics. A philanthropist at heart, he's significantly contributed to rural education and disaster relief efforts."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
