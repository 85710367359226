/*
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMN0OxdddxkO0XWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMx,               .,cdONMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMl    ',lx0WNo:.          'lkNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMXxl;,.'o0WNkoc0O:                 .cOWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMWd.         .ddlc;.                      ,dXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMNMMMMMMMMMMMM
MMMMMMMW.                                ..';:codk0XMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWx'dMMMMMMMMMMMM
MMMMMMMd  'oOKK0KK0dc;''.,:::clcclllc;,..           ..,:coxk0KNWMMMMMMMMMMMMMMMN0xc.  'MMMMMMMMMMMMM
MMMMMMMk'KMW0xo:. .cKMWKkdoxO00kkxxxkO00kkxdl;,''.                ....''''...        ,WMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMXc  'KMMNkxdxONXOxxxkkkKNNKK0OO0KWNKko:'.                         .kMMMMMMMMMMWKWMM
MMMMMMMMMMMMMMMMMMMK.  lMMMXxxkk0NWXkxOkKKNWMMWWNNWWWWWWWWWXOxl;'.              'lKMMMMMMMMMMNK0XMMM
MMMMMMMMMMMMMMMMMMMMN.  ,WMMWkOKK0NMMNNNXNNWWWWMMMWWWNWWNNNNNNWWWWMWNK0OkkkO0KWMMMMMMMMMWWNXOO0KMMMM
MMMMMMMMMMMMMMMMMMMMMX   'WMMMXNNNNNWMMWNNNNNWNNWMMMMWWNNNNNNNNNNNNNNNWWWXNWWWWNNNNNNKkloOxl:,cWMMMM
MMMMMMMMMMMMMMMMMMMMMMO   ,MMMMNNXXXNNMMMWNNXNNNWNNWMMMMWWWNNNNNNNNXXNXxXKk0OKNOxxO0o':,;;;,,dWMMMMM
MMMMMMMMMMMMMMMMMMMMMMMo   :MMMMNXXXNXXNMMMNXXXNNNNNNNWMMMMMMWNXXK0OKKxdO0d:;:c;c:od,'',o;:xNMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMM,   dMMMMXKNNXKO0WMMMXXXXNNXXK00NNMMMMMMMNKxl;llc,,,,',,;;,,''',lOWMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMW'   OMMMMXXXXXK0XNMMMWKOkkx0OOkolok0WMMMMMMMWN0OxdoollllodkOKNMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMW:   KMMMMNXXXXXKKkWMMMNx,,lc;;'',,,,lkXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMM0,  0MMMMN0dclllod0MMMMO:,,,,,,'',,,',;okKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOdNMMMMXo::c,,,,lXMMMM0o,',',,',,'',,,'.;ldk0KNWMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKd;,,,,,,oXMMMMW0dc,,,,',,,,,'''''',cxXMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKxo:;,,'cxKWMMMMWKOxdlcc:ccldxOXMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWNNNWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
 - Copyright 2023 Bird Eye View Private Ltd. - 

* Project: Birdeyeview responsive website
 * Technologies Used:
 *   - Programming Languages: JavaScript (ES6+), JSON, HTML, CSS
 *   - Frontend Framework: React.js
 *   - Backend Framework: Node.js
 *   - CSS Preprocessor: SCSS
 *   - UI Framework: Material-UI
 *   - Routing: React Router
 *   - State Management: -
 *   - Form Handling: Formik 
 *   - HTTP Requests: -
 *   - Data Visualization: -
 *   - Authentication: -
 *   - Animation: react-slick
 *   - Code Bundler: -
 *   - Package Manager: npm
 *   - Version Control: Git
 *   - Deployment: current->Shapehost, next->nginx
 *
 * Customizations:
 *   - 
 *
 * Author: Divyprakash Dhurandhar
 * Contact: divyaprakash.0426@gmail.com
 * GitHub: github.com/divyaprakash0426

Thanks to: 
- alexandre-debieve on Unsplash
- kvistholt-photography on Unsplash
- tim-mossholder on Unsplash
- chris-reid on Unsplash

=============================================
Built on top of Material Kit by Creative Tim:
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
=============================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
//import React, { useState, useEffect } from "react";
import React from "react";
import Slider from "react-slick";
import "../../../styles.css";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
//import MKButton from "components/MKButton";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import CenteredFooter from "examples/Footers/CenteredFooter";

// Coworking page sections
import Information from "pages/LandingPages/Home/sections/Information";
import Testimonials from "pages/LandingPages/Home/sections/Testimonials";
import AboutUs from "pages/LandingPages/Home/sections/AboutUs";
//import Places from "pages/LandingPages/Coworking/sections/Places";

// Routes
import routes from "routes";
//import footerRoutes from "footer.routes";

// Images
import bgImage1 from "assets/images/illustrations/alexandre-debieve.jpg";
import bgImage2 from "assets/images/illustrations/chris-ried.jpg";
import bgImage3 from "assets/images/illustrations/tim-mossholder.jpg";
import bgImage4 from "assets/images/illustrations/drone2.jpeg";
import bgImage5 from "assets/images/illustrations/kvistholt-photography.jpg";

function Home() {
  const images = [bgImage1, bgImage2, bgImage3, bgImage4, bgImage5];

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
  };

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <Slider {...settings}>
        {images.map((image, index) => (
          <MKBox
            key={index}
            minHeight="75vh"
            width="100%"
            sx={{
              backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.5),
                  rgba(gradients.dark.state, 0.5)
                )}, url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Container>
              <Grid
                container
                item
                xs={12}
                md={7}
                justifyContent={{ xs: "center", md: "start" }}
                sx={{ textAlign: { xs: "center", md: "left" }, mt: 20 }}
              >
                <MKTypography
                  variant="h1"
                  color="white"
                  sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                  })}
                >
                  Innovating tomorrow&apos;s tech today
                </MKTypography>
                <MKTypography
                  variant="body1"
                  color="white"
                  mt={1}
                  pr={{ md: 12, lg: 24, xl: 32 }}
                  opacity={0.8}
                >
                  At the frontier of technological advancement, we&apos;re not just working;
                  we&apos;re shaping the future. Join us in revolutionizing the landscapes of
                  engineering, design, and innovation.
                </MKTypography>
              </Grid>
            </Container>
          </MKBox>
        ))}
      </Slider>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Testimonials />
        <AboutUs />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <CenteredFooter />
      </MKBox>
    </>
  );
}

export default Home;
