/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Coworking page component
import AboutUsOption from "pages/LandingPages/Home/components/AboutUsOption";

function AboutUs() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container>
          <Grid item xs={12} lg={5}>
            <MKTypography variant="h3" my={1}>
              Meet Our Team&apos;s Strengths
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Our team is built on a foundation of expertise, collaboration, and innovation.
              Discover the qualities that set us apart and drive our success.
            </MKTypography>
            <MKTypography
              component="a"
              href="#"
              variant="button"
              color="info"
              fontWeight="regular"
              sx={{
                width: "max-content",
                display: "flex",
                alignItems: "center",

                "& .material-icons-round": {
                  fontSize: "1.125rem",
                  transform: "translateX(3px)",
                  transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                },

                "&:hover .material-icons-round, &:focus .material-icons-round": {
                  transform: "translateX(6px)",
                },
              }}
            >
              Learn More About Our Team
              <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
            </MKTypography>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <AboutUsOption
                icon="school" // Representing expertise
                //iconColor={red[500]} // Setting icon color to red
                content={
                  <>
                    Expertise
                    <br />
                    Leveraging years of industry experience to deliver results.
                  </>
                }
              />
              <AboutUsOption
                icon="group_work" // Representing collaboration
                //iconColor={red[500]}
                content={
                  <>
                    Collaboration
                    <br />
                    Working together to achieve a common goal.
                  </>
                }
              />
              <AboutUsOption
                icon="lightbulb" // Representing innovation
                //iconColor={red[500]}
                content={
                  <>
                    Innovation
                    <br />
                    Pioneering solutions for complex challenges.
                  </>
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutUs;
