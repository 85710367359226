/*
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMN0OxdddxkO0XWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMx,               .,cdONMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMl    ',lx0WNo:.          'lkNMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMXxl;,.'o0WNkoc0O:                 .cOWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMWd.         .ddlc;.                      ,dXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMNMMMMMMMMMMMM
MMMMMMMW.                                ..';:codk0XMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWx'dMMMMMMMMMMMM
MMMMMMMd  'oOKK0KK0dc;''.,:::clcclllc;,..           ..,:coxk0KNWMMMMMMMMMMMMMMMN0xc.  'MMMMMMMMMMMMM
MMMMMMMk'KMW0xo:. .cKMWKkdoxO00kkxxxkO00kkxdl;,''.                ....''''...        ,WMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMXc  'KMMNkxdxONXOxxxkkkKNNKK0OO0KWNKko:'.                         .kMMMMMMMMMMWKWMM
MMMMMMMMMMMMMMMMMMMK.  lMMMXxxkk0NWXkxOkKKNWMMWWNNWWWWWWWWWXOxl;'.              'lKMMMMMMMMMMNK0XMMM
MMMMMMMMMMMMMMMMMMMMN.  ,WMMWkOKK0NMMNNNXNNWWWWMMMWWWNWWNNNNNNWWWWMWNK0OkkkO0KWMMMMMMMMMWWNXOO0KMMMM
MMMMMMMMMMMMMMMMMMMMMX   'WMMMXNNNNNWMMWNNNNNWNNWMMMMWWNNNNNNNNNNNNNNNWWWXNWWWWNNNNNNKkloOxl:,cWMMMM
MMMMMMMMMMMMMMMMMMMMMMO   ,MMMMNNXXXNNMMMWNNXNNNWNNWMMMMWWWNNNNNNNNXXNXxXKk0OKNOxxO0o':,;;;,,dWMMMMM
MMMMMMMMMMMMMMMMMMMMMMMo   :MMMMNXXXNXXNMMMNXXXNNNNNNNWMMMMMMWNXXK0OKKxdO0d:;:c;c:od,'',o;:xNMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMM,   dMMMMXKNNXKO0WMMMXXXXNNXXK00NNMMMMMMMNKxl;llc,,,,',,;;,,''',lOWMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMW'   OMMMMXXXXXK0XNMMMWKOkkx0OOkolok0WMMMMMMMWN0OxdoollllodkOKNMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMW:   KMMMMNXXXXXKKkWMMMNx,,lc;;'',,,,lkXMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMM0,  0MMMMN0dclllod0MMMMO:,,,,,,'',,,',;okKWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMWOdNMMMMXo::c,,,,lXMMMM0o,',',,',,'',,,'.;ldk0KNWMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMKd;,,,,,,oXMMMMW0dc,,,,',,,,,'''''',cxXMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWKxo:;,,'cxKWMMMMWKOxdlcc:ccldxOXMMMMMMMMMMMMMMMMMMMMMMMMMM
MMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMWNNNWMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMMM
 - Copyright 2023 Bird Eye View Private Ltd. - 

* Project: Birdeyeview responsive website
 * Technologies Used:
 *   - Programming Languages: JavaScript (ES6+), JSON, HTML, CSS
 *   - Frontend Framework: React.js
 *   - Backend Framework: Node.js
 *   - CSS Preprocessor: SCSS
 *   - UI Framework: Material-UI
 *   - Routing: React Router
 *   - State Management: -
 *   - Form Handling: Formik 
 *   - HTTP Requests: -
 *   - Data Visualization: -
 *   - Authentication: -
 *   - Animation: react-slick
 *   - Code Bundler: -
 *   - Package Manager: npm
 *   - Version Control: Git
 *   - Deployment: current->Shapehost, next->nginx
 *
 * Customizations:
 *   - 
 *
 * Author: Divyprakash Dhurandhar
 * Contact: divyaprakash.0426@gmail.com
 * GitHub: github.com/divyaprakash0426

Thanks to: 
- alexandre-debieve on Unsplash
- kvistholt-photography on Unsplash
- tim-mossholder on Unsplash
- chris-reid on Unsplash

=============================================
Built on top of Material Kit by Creative Tim:
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
=============================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
